.open-jobs-highlight {
  background-color: var(--color-secondary-400);
  color: var(--color-primary-700);

  &__content {
    margin-bottom: 2rem;

    @media screen and (min-width: 600px) {
      margin-bottom: 2.5rem;
    }
    @media screen and (min-width: 900px) {
      margin-bottom: 3rem;
    }
  }

  &__total-count {
    font-weight: bold;
    font-size: calc(100 / 16 * 1rem);
    line-height: calc(108 / 16 * 1rem);

    @media screen and (max-width: 600px) {
      font-size: calc(64 / 16 * 1rem);
      line-height: calc(72 / 16 * 1rem);
    }
  }

  &__popular-categories {
    grid-column: 1/-1;

    @media screen and (min-width: 1280px) {
      grid-column: span 8;
    }

    &__chips {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      gap: 1rem;
      margin-top: 1rem;

      @media screen and (min-width: 600px) {
        gap: 1.5rem;
        margin-top: 1.5rem;
      }
    }
  }

  &__latest-openings {
    grid-column: 1/-1;

    &__see-more {
      grid-column: 1/-1;
      width: fit-content;
    }
  }
}
